import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useConfig } from '../../../context/ConfigsContext';
import { AddEmpresa } from '../../data/AdminEmpresa';
import { AddDocBussiness } from '../../../data/ManageBusiness';
import { SelectorID, SelectorOp } from '../../../components/inputs/Selector';
import { Input, InputIcoOp, InputPhone } from '../../../components/inputs/Input';
import { UpLoadDoc } from '../../../components/upload/uploadDoc';
import { BtnGeneral } from '../../../components/buttons/Boton';
import { ModalValidar } from '../../../components/modales/Modales';
import { useGetCodigos } from '../../hooks/useGetCodes';
import { HuboUnError, PopUpValid } from '../../../components/mensajes/MensajesClient';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { storage } from '../../../firebase/firebaseConfig';
import { getUnixTime } from 'date-fns';
import Check from '../../../img/icons/tick-square.svg';
import Face from '../../../img/icons/face.svg';
import Insta from '../../../img/icons/insta.svg';
import { useNavigate } from 'react-router-dom';

const CreateBusiness = () => {

  const navigate = useNavigate();
  const {OpenModal, setProcesando} = useConfig();
  const fecha = new Date();
  const [name, setName] = useState('');
  const [legalName, setLegalName] = useState('');
  const [logo, setLogo] = useState('');
  //Document
  const [typeID, setTypeID]= useState('ABN');
  const [numberID, setNumberID]= useState('');
  const [documento, setDocumento] = useState('');
  const [progress, setProgress] = useState('');
  //Referido
  const [code, setCode] = useState('');
  const [codes, setCodes] = useState('');
  const [codeID, setCodeID] = useState('');
  const [codigos] = useGetCodigos();
  //Contacto
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber]= useState('');
  const [phoneNumber2, setPhoneNumber2]= useState('');
  const [facebook, setFacebook] = useState('');
  const [instagram, setInstagram] = useState('');
  const [pais, setPais] = useState('Australia');
  const [ciudad, setCiudad] = useState('');
  const [direccion, setDireccion] = useState('');
  //cuenta
  const [countryBank, setCountryBank] = useState('Australia');
  const [bank, setBank] = useState('');
  const [titular, setTitular] = useState('');
  const [accountType, setAccounType] = useState('');
  const [bsb, setBsb] = useState('');
  const [bsb2, setBsb2] = useState('');
  const [numeroCuenta, setNumeroCuenta] = useState('');
  const [numeroCuenta2, setNumeroCuenta2] = useState('');

  //VALIDATION
  const [spnName, setSpnName] = useState(false);
  const [spnLogo, setSpnLogo] = useState(false);
  const [spnLegalName, setSpnLegalName] = useState(false);
  const [spnCode, setSpnCode] = useState(false);
  const [spnEmail, setSpnEmail] = useState(false);
  const [spnPhone, setSpnPhone] = useState(false);
  const [spnPhone2, setSpnPhone2] = useState(false);
  const [spnCiudad, setSpnCiudad] = useState(false);
  const [spnDir, setSpnDir] = useState(false);
  const [spnBank, setSpnBank] = useState(false);
  const [spnTitular, setSpnTitular] = useState(false);
  const [spnBsb, setSpnBsb] = useState(false);
  const [spnBsb2, setSpnBsb2] = useState(false);
  const [spnNCuenta, setSpnNCuenta] = useState(false);
  const [spnNCuenta2, setSpnNCuenta2] = useState(false);
  const [spnNumberID, setSpnNumberID] = useState(false);
  const [spnArchivo,setSpnArchivo] = useState(false);
 
  const [altName, setAltName] = useState('');
  const [altLogo, setAltLogo] = useState('');
  const [altLegalName, setAltLegalName] = useState('');
  const [altCode, setAltCode] = useState('');
  const [altEmail, setAltEmail] = useState('');
  const [altPhone, setAltPhone] = useState('');
  const [altPhone2, setAltPhone2] = useState('');
  const [altCiudad, setAltCiudad] = useState('');
  const [altDir, setAltDir] = useState('');
  const [altBank, setAltBank] = useState('');
  const [altTitular, setAltTitular] = useState('');
  const [altBsb, setAltBsb] = useState();
  const [altBsb2, setAltBsb2] = useState();
  const [altNCuenta, setAltNCuenta] = useState();
  const [altNCuenta2, setAltNCuenta2] = useState();
  const [altNumberID, setAltNumberID] = useState(false);
  const [altArchivo, setAltArchivo] = useState('');

  //OPCIONES
  const countries =[{id:1, text: 'Australia'},{id:2, text: 'Colombia'}]
  const typesAccount = [{id:1, text: 'Cuenta de Ahorros'}, {id:2, text: 'Cuenta Corriente'}]
  const docsTypes = [{id:1, text: 'ABN'}, {id:2, text: 'NIT'}, {id:3, text: 'CC'}]

  //FUNCIONES
  const tranSpaces = (nombre) =>{ const id = nombre.replace(/\s+/g, ''); return id;}
  const EmailTest = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/;

  //SELECTOR CODIGOS
  useEffect(()=>{
		if(codigos.length > 0){
			const result = codigos.map(code => (
				{ id: code.id, 
					text: code.id
        }
			))
			setCodes(result)
		}
	},[codigos])

  const Validar = (e) =>{
    e.preventDefault();
    if(legalName === ''){setSpnLegalName(true); setAltLegalName('Ingresa el Nombre'); return;}
    if(name === ''){setSpnName(true); setAltName('Ingresa el Nombre'); return;}
    if(logo === ''){setSpnLogo(true); setAltLogo('Ingresa el nombre del Logo'); return;}
    if(numberID === ''){setSpnNumberID(true); setAltNumberID('Ingresa el Número'); return;}
    if(!documento){setSpnArchivo(true); setAltArchivo("Selecciona el Archivo"); return;}
    if(!documento[0].name.match(/\.(jpg|JPG|jpeg|png|pdf|heic|HEIC|heif|HEIF)$/)){
      setSpnArchivo(true); setAltArchivo("Archivo no válido, solo Imagen o PDF"); return;}
    const size = Math.round(documento[0].size / 1024);
    if(size >= 1024){setSpnArchivo(true); setAltArchivo("El tamaño máximo del archivo es de 1mb"); return;}
    if(email === ''){setSpnEmail(true); setAltEmail('Ingresa un correo electrónico'); return;}
    if(!EmailTest.test(email)){setSpnEmail(true); setAltEmail('Ingresa un correo válido'); return;}
    if(phoneNumber === ''){setSpnPhone(true); setAltPhone('Ingresa el celular'); setAltPhone2(''); return;}
    if(ciudad===''){setSpnCiudad(true); setAltCiudad('Ingresa la ciudad'); return;}
    if(direccion===''){setSpnDir(true); setAltDir('Ingresa la dirección'); return;}
    if(!bank) {setSpnBank(true); setAltBank('Ingresa el nombre del Banco'); return;}
    if(!titular) {setSpnTitular(true); setAltTitular('Ingresa el nombre'); return;}
    if(!bsb){setSpnBsb(true); setAltBsb('Ingresa el número BSB'); return;}
    if(bsb.length !== 6){setSpnBsb(true); setAltBsb('El número debe ser de 6 dígitos'); return;}
    if(!bsb2){setSpnBsb2(true); setAltBsb2('Confirma el número BSB'); return;}
    if(bsb2 !== bsb){setSpnBsb2(true); setAltBsb2('El número BSB no es igual'); return;}
    if(!numeroCuenta) {setSpnNCuenta(true); setAltNCuenta('Ingresa el número de la cuenta'); return;}
    if(!numeroCuenta2) {setSpnNCuenta2(true); setAltNCuenta2('Confirma el número de la cuenta'); return;}
    if(numeroCuenta2 !== numeroCuenta){setSpnNCuenta2(true); setAltNCuenta2('El número cuenta no es igual'); return;}
    OpenModal(
      <ModalValidar 
        accion={Submit} 
        titulo={'Completar Registro'}
        mensaje={'¿Todos los datos son Correctos?'}
        botonName={'Sí, Completar'}
        botonClass={'btnGeneral primaryC'}
      />)
  }

  const DocLoad = (docRef) =>{
    const comprobante = documento[0];
    const nombreArchivo = `${docRef.id}document.${comprobante.name.split(".").pop()}`
    const storageRef = ref(storage, `empresas/${docRef.id}/${nombreArchivo}`);
    const uploadTask = uploadBytesResumable(storageRef, comprobante);
    uploadTask.on("state_changed",(snapshot) => {
      const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      setProgress(progress);
    },
    (error) => {
      console.log('here0')
      setProcesando(false)
      OpenModal(<HuboUnError />);
    },
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then((url) => {
        AddDocBussiness({
          id: docRef.id,
          document: url
        })
      }).then(() =>{
        OpenModal(<PopUpValid mensaje={'Se registrado la empresa Correctamente.'}/>);
        setProcesando(false)
        navigate('/empresas')
      }).catch(()=>{
        setProcesando(false)
        OpenModal(<HuboUnError/>);
      })
    })
  }

  const Submit = () =>{
    setProcesando(true)
    AddEmpresa({
      data:{
        name: name,
        ...codeID && {code: codeID},
        document:{
          legalName: legalName,
          url: '',
          typeID: typeID,
          numberID: numberID,
        },
        contact:{
          email: email,
          phones: [phoneNumber],
          instagram: instagram,
          facebook: facebook,
        },
        address:{
          address1:{
            address: direccion,
            country: pais,
            city: ciudad,
            default: true
          }
        },
        account:{
          country: countryBank,
          bank: bank,
          titular: titular,
          account: tranSpaces(numeroCuenta),
          bsb: tranSpaces(bsb),
          ...accountType && {type: accountType},
        },
        logo: logo,
        registerDate: getUnixTime(fecha),
        status: 'active'
      }
    }).then((docRef)=>{
      DocLoad(docRef)
    }).catch(()=>{
      setProcesando(false)
      OpenModal(<HuboUnError/>);
    })
  }

  return (
    <div className='dashPage'>
			<Helmet><title>Registrar Empresa</title></Helmet>
      <p className='pageTitle'>Registrar Empresa</p>

      <div className="card">
        <p className='cardTitle'>Información</p>
        <Input label={'Nombre Legal'} value={legalName} setValue={setLegalName} spn={spnLegalName}
          setSpn={setSpnLegalName} alt={altLegalName} type={'text'} fill={'on'} name="nombre" />
        <Input label={'Nombre'} value={name} setValue={setName} spn={spnName}
          setSpn={setSpnName} alt={altName} type={'text'} fill={'on'} name="nombre" />
        <Input label={'Logo'} value={logo} setValue={setLogo} spn={spnLogo}
          setSpn={setSpnLogo} alt={altLogo} type={'text'} fill={'on'} name="logo" />
        <SelectorOp label={'Tipo de ID'} value={typeID} setValue={setTypeID} options={docsTypes} />
        <Input label={'Número de ID'} value={numberID} setValue={setNumberID} spn={spnNumberID}
          setSpn={setSpnNumberID} alt={altNumberID} type={'text'} fill={'off'} name="address"/>
        <UpLoadDoc archivo={documento} setArchivo={setDocumento} spn={spnArchivo} setSpn={setSpnArchivo} alt={altArchivo} />
        <div className="noSee"><progress value={progress ? progress : 0} max="100"></progress></div>
      </div>
      
      <div className='card'>
        <p className='cardTitle'>Contacto</p>
        <Input label={'Email'} value={email} setValue={setEmail} spn={spnEmail}
          setSpn={setSpnEmail} alt={altEmail} type={'email'} fill={'on'} name="email" />
        <div className='rowInput2'>
          <InputPhone label={'Celular 1'} setValue={setPhoneNumber} spn={spnPhone} setSpn={setSpnPhone} alt={altPhone} />
          <InputPhone label={'Celular 2'} setValue={setPhoneNumber2} spn={spnPhone2} setSpn={setSpnPhone2} alt={altPhone2} />
        </div>
        <div className='rowInput2'>
          <InputIcoOp label={'Instagram (opcional)'} value={instagram} setValue={setInstagram} Img={Insta} 
            type={'text'} fill={'on'} name="instagram" />
          <InputIcoOp label={'Facebook (opcional)'} value={facebook} setValue={setFacebook} Img={Face} 
            type={'text'} fill={'on'} name="facebook" />
        </div>
      </div>

      <div className="card">
        <p className='cardTitle'>Dirección</p>
        <SelectorOp label={'País'} value={pais} setValue={setPais} options={countries} />
        <Input label={'Ciudad'} value={ciudad} setValue={setCiudad} spn={spnCiudad}
          setSpn={setSpnCiudad} alt={altCiudad} type={'text'} fill={'off'} name="city"/>
        <Input label={'Dirección'} value={direccion} setValue={setDireccion} spn={spnDir}
          setSpn={setSpnDir} alt={altDir} type={'text'} fill={'off'} name="address"/>
      </div>

      <div className="card">
        <p className='cardTitle'>Datos Bancarios</p>
        <div className='formCard'>
          <SelectorOp label={'Cuenta En'} value={countryBank} setValue={setCountryBank} options={countries}  />
          <Input label={'Banco'} value={bank} setValue={setBank} spn={spnBank}
            setSpn={setSpnBank} alt={altBank} type={'text'} fill={'on'} />
          <Input label={'Titular'} value={titular} setValue={setTitular} spn={spnTitular}
            setSpn={setSpnTitular} alt={altTitular} type={'text'} fill={'on'} />
          {countryBank === 'Australia' ? 
            <div className='rowInput2'> 
              <Input label={'BSB'} value={bsb} setValue={setBsb} spn={spnBsb}
                setSpn={setSpnBsb} alt={altBsb} type={'text'} fill={'on'} />
              <Input label={'Confirmar BSB'} value={bsb2} setValue={setBsb2} spn={spnBsb2}
                setSpn={setSpnBsb2} alt={altBsb2} type={'text'} fill={'on'} />
            </div>
          :
            <SelectorOp label={'Tipo de Cuenta'} value={accountType} setValue={setAccounType} options={typesAccount}  />
          }
          <div className='rowInput2'>
            <Input label={'Número de cuenta'} value={numeroCuenta} setValue={setNumeroCuenta} spn={spnNCuenta}
              setSpn={setSpnNCuenta} alt={altNCuenta} type={'number'} fill={'off'} name="numeroCuenta" />

            <Input label={'Confirma Número de cuenta'} value={numeroCuenta2} setValue={setNumeroCuenta2} spn={spnNCuenta2}
              setSpn={setSpnNCuenta2} alt={altNCuenta2} type={'number'} fill={'off'} name="numeroCuenta" />
          </div> 
        </div>
      </div>

      <div className='card'>
        <p className='cardTitle'>Colcambios</p>
        <SelectorID label={'Código Referido'} value={code} setValue={setCode} setID={setCodeID}
          options={codes} alt={altCode} spn={spnCode} setSpn={setSpnCode} />
        <BtnGeneral text={'Registrar'} img={Check} side={'L'} disabled={false} type="button" 
          action={Validar} clases={'primaryC'} large={true} />
      </div>
    </div>
  )
}

export default CreateBusiness;