import React, { useEffect, useState }  from "react";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { InvoiceWallet } from "../../../../components/documents/InvoiceWallet";
import { format, fromUnixTime } from "date-fns";
import { es } from "date-fns/locale";
import { Selector } from "../../../../components/inputs/Selector";
import { BtnGeneral } from "../../../../components/buttons/Boton";
import ImgFile from '../../../../img/icons/file-text.svg';
import { ReceiptWallet } from "../../../../components/documents/ReceiptWallet";

const generarFactura = async (data, idioma, id, type) => {

	const DateNew = (fecha) => { return format(fromUnixTime(fecha), "dd'-'MM'-'yyyy", { locale: es }); };
	const DateOld = (fecha) => { return format(new Date(fecha), "dd'-'MM'-'yyyy", { locale: es }); };

  let titulo
  if(idioma === 'Es'){titulo = "Instrucciones de Pago "}else{titulo = "Invoice "}
  const fileName = titulo + id + ".pdf"
  const blob = await pdf((
    <InvoiceWallet
      cuentas={data.colcambios}
      tipo={type}
      Idioma={idioma}
      empresa={data.empresa}
      logo={data.logo}
      fecha={DateNew(data.date)}
      expiracion={DateOld(data.dateExp)}
      numerorecibo={id}
      nombre={data.cliente ? data.cliente.nombre : data.nameWallet }
      id={data.cliente ? data.cliente.numeroDoc : data.userInfo.document.numberId}
      razon={data.cliente ? data.cliente.razon : 'Recarga de la Billetera'}
      razonEn={data.cliente ? data.cliente.razon : 'Wallet savings'}
      telefono={data.cliente ? data.cliente.phone : data.userInfo.phone}
      email={data.cliente ? data.cliente.email : data.userInfo.email}
      montocop={data.values.total}
      pagoImpuesto={data.values.taxes !== 0 ? true : false}
      cargos={data.values.taxes}
      tasadecambio={data.values.tasa}
      montoaud={data.values.ammount}
    />
  )).toBlob();
  saveAs(blob, fileName);
};

const GenerarInvoice = ({data}) =>{

  const DateNew = (fecha) => { return format(fromUnixTime(fecha), "dd'-'MM'-'yyyy", { locale: es }); };
  
  const [idiomaSel, setIdiomaSel] = useState('Es');
	const [idioma, setIdioma] = useState('Español');
	const idiomas = [{id: '1', text: 'Español' }, {id: '2', text: 'Inglés'}];
	const [spnIdioma, setSpnIdioma] = useState(false);
	useEffect(()=>{if(idioma === 'Español'){setIdiomaSel('Es')}else{setIdiomaSel('En')}},[idioma])

  const generarFactura = async () => {
    let titulo
    if(idiomaSel === 'Es'){titulo = "Factura de Pago "}else{titulo = "Invoice "}
    const fileName = titulo + data.id.substring(0, 6).toUpperCase() + ".pdf"
    const blob = await pdf((
      <ReceiptWallet
        Idioma={idiomaSel}
        empresa={data.empresa}
        logo={data.logo}
        fecha={DateNew(data.date)}
        numerorecibo={data.id.substring(0, 6).toUpperCase()}
        razon={data.referency}

        montoaud={data.values.ammount}
        metodo={data.service}
        fee={data.values.fee}
        total={data.values.total}

        banco={data.account.bank}
        titular={data.account.accountName}
        numerobsb={data.account.bsb}
        numerocuenta={data.account.accountNumber}
        
        nombre={data.cliente ? data.cliente.nombre : data.nameWallet }
        id={data.cliente ? data.cliente.numeroDoc : data.userInfo.document.numberId}
        telefono={data.cliente ? data.cliente.phone : data.userInfo.phone}
        email={data.cliente ? data.cliente.email : data.userInfo.email}
      />
    )).toBlob();
    saveAs(blob, fileName);
  };

  return <>
    <Selector label='Idioma del Recibo' value={idioma} setValue={setIdioma} options={idiomas} alt={''} spn={spnIdioma} setSpn={setSpnIdioma} />
    <BtnGeneral text={'Descargar'} img={ImgFile} side={'L'} disabled={false} type="button" 
      action={generarFactura} clases={'primaryC'} large={false} />
  </>
}

export {
  generarFactura,
  GenerarInvoice
}