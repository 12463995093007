import React, {useEffect, useState} from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import { useConfig } from '../../../context/ConfigsContext';
import { useGetTransfer } from '../../hooks/useGetTransfers';
import CalificarServicio from './components/CalificarServicio';
import { LoadingPage } from '../../../components/loading/Loadings';
import CardDoc from '../../../components/cards/CardDoc';
import Comprobante from '../../../views/pages/transferencia/components/Comprobante';
import '../../../styles/transferencias.css';
import '../../../styles/rating.css';
import ExportInvoice from '../../../views/pages/transferencia/functions/ExportInvoice';

const Trasnferencia = () =>{

	const { usuario } = useAuth();
	const {FormatCop, FormatAud,  FormatNzd, horaCO, horaAU, horaNZ} = useConfig();
	const navigate = useNavigate();

	//TRANSFERENCIA
	const { country, id } = useParams();
	const [table, setTable] = useState();

  useEffect(()=>{
    if(country){
      if(country === 'australia'){setTable('transferencias')}
      if(country === 'colombia'){setTable('colombia')}
      if(country === 'newzealand'){setTable('newzealand')}
    }
  },[country])

	const CoinTo = (number) =>{
		if(country === 'australia'){return FormatAud(number)}
		if(country === 'colombia'){return FormatCop(number)}
		if(country === 'newzealand'){return FormatNzd(number)}
	}

	const CoinFrom = (number) =>{
		if(country === 'australia'){return FormatCop(number)}
		if(country === 'colombia'){return FormatAud(number)}
		if(country === 'newzealand'){return FormatCop(number)}
	}

	const CoinFee = (number) =>{
		if(country === 'australia'){return FormatAud(number)}
		if(country === 'colombia'){return FormatAud(number)}
		if(country === 'newzealand'){return FormatNzd(number)}
	}

	const idNuevo = id.substring(0, 6).toUpperCase();
	const [transferencia, loading] = useGetTransfer(id, table);
	// const [externo, setExterno] = useState('');

	const [fechaTrans, setFechaTrans] = useState();
	const [fechaEmail, setFechaEmail] = useState();
	const [fechaPago, setFechaPago] = useState();
	const [estadoTrans, setEstadoTrans] = useState();
	const [razonTrans, setRazonTrans] =  useState();
	const [observaciones, setObservaciones] = useState();
	const [calificada, setCalificada] = useState(false);
	const [colcambios, setColcambios] = useState();
	
	//VALORES
	const [montoAud, setMontoAud] = useState(0);
	const [servicio, setServicio] = useState(0);
	const [total, setTotal] = useState(0);
	const [metodo, setMetodo] = useState(0);
	const [fee,setFee] = useState(0);
	const [cargos,setCargos] = useState(0);
	const [tasadeCambio, setTasadeCambio] = useState(0);
	const [cuatroMil, setCuatroMil] = useState(0);
	//REMITENTE
	const [autoEnvio, setAutoEnvio] = useState();
	const [rteAppId, setRteAppId] = useState();
	const [rteNombre, setRteNombre] = useState();
	const [rteApellido, setRteApellido] = useState();
	//DESTINATARIO
	const [dnoAppId, setDnoAppId] = useState();
	const [dnoNombre, setDnoNombre] = useState();
	const [dnoApellido, setDnoApellido] = useState();
	const [dnoTipoId, setDnoTipoId] = useState();
	const [dnoNoId, setDnoNoId] = useState();
	const [dnoCelular, setDnoCelular]  = useState();
	//CODIGO PROMOCIONAL
	const [proCodigo, setProCodigo] = useState('');
	const [proTipo, setProTipo] = useState('');
	const [proDescuento, setProDescuento] = useState('');
	const [proAgenciaId, setProAgenciaId] = useState('');
	//CUENTA
	const [titular, setTitular] = useState();
	const [nombreBanco, setNombreBanco] = useState();
	const [numeroBSB, setNumeroBSB] = useState();
	const [numerodeCuenta, setNumerodeCuenta] = useState();
	const [tipoCuenta, setTipoCuenta] = useState('');
	//DOCUMENTOS
	const [comprobantePago, setComprobantePago] = useState();
	const [factura, setFactura] = useState(false);

	useEffect(() => {
		if(transferencia){
			// if(transferencia.cliente){setExterno(true)}

			setFechaTrans(transferencia.fecha);
			setFechaEmail(transferencia.fecha);
			setFechaPago(transferencia.fechaMaxPago);
			setEstadoTrans(transferencia.estado);
			setRazonTrans(transferencia.razon);
			setColcambios(transferencia.colcambios);

			setMontoAud(transferencia.transferencia.to ? transferencia.transferencia.to : transferencia.transferencia.montoAud);
			setServicio(transferencia.transferencia.servicio);
			setTotal(transferencia.transferencia.from ? transferencia.transferencia.from : transferencia.transferencia.totalPagado);
			setMetodo(transferencia.transferencia.metodo);
			setFee(transferencia.transferencia.fee);
			setCargos(transferencia.transferencia.cargos);
			setTasadeCambio(transferencia.transferencia.tasa);
			setCuatroMil(transferencia.transferencia.cuatromil);

			setAutoEnvio(transferencia.autoEnvio);
			setRteAppId(transferencia.usuario)
			setRteNombre(transferencia.remitente.nombre);
			setRteApellido(transferencia.remitente.apellido);
			setDnoAppId(transferencia.destinatarioId)
			setDnoNombre(transferencia.destinatario.nombre);
			setDnoApellido(transferencia.destinatario.apellido);
			setDnoTipoId(transferencia.destinatario.tipoId);
			setDnoNoId(transferencia.destinatario.numeroId);
			setDnoCelular(transferencia.destinatario.celular);

			setTitular(transferencia.cuenta.titular);
			setNombreBanco(transferencia.cuenta.banco);
			setNumeroBSB(transferencia.cuenta.bsb);
			setNumerodeCuenta(transferencia.cuenta.numeroCuenta);
			setTipoCuenta(transferencia.cuenta.tipo)

			setObservaciones(transferencia.observaciones);
			setCalificada(transferencia.calificada);

			setComprobantePago(transferencia.comprobantePago);
			setFactura(transferencia.factura);

			if(transferencia.promocional){
				setProTipo(transferencia.promocional.tipo);
				setProCodigo(transferencia.promocional.codigo);
				setProDescuento(transferencia.promocional.descuento);
				setProAgenciaId(transferencia.promocional.agenciaId);
			}
		}
	}, [transferencia, usuario, navigate]);

	return(
		<div className='dashPage'>
      <Helmet><title>Transferencia</title></Helmet>
      <p className="pageTitle">Transferencia</p>

			{loading ? <LoadingPage /> : <>
				<div className="cardTransfer">
					<div className="NTransfer"><p><span>Referencia</span>{idNuevo}</p></div>
					<div className="STransfer">
						<p><span>Estado</span>{estadoTrans}</p>
						<p><span>Método</span>{metodo}</p>
					</div>
					{country === 'australia' && <>
						<div><p className="FTransfer"><span>Fecha en Colombia</span>{horaCO(fechaTrans)}</p></div>
						<div><p className="FTransfer"><span>Fecha en Australia</span>{horaAU(fechaTrans)}</p></div>
					</>}
					{country === 'colombia' && <>
						<div><p className="FTransfer"><span>Fecha en Australia</span>{horaAU(fechaTrans)}</p></div>
						<div><p className="FTransfer"><span>Fecha en Colombia</span>{horaCO(fechaTrans)}</p></div>
					</>}
					{country === 'newzealand' && <>
						<div><p className="FTransfer"><span>Fecha en Colombia</span>{horaCO(fechaTrans)}</p></div>
						<div><p className="FTransfer"><span>Fecha en Nueva Zelanda</span>{horaNZ(fechaTrans)}</p></div>
					</>}
				</div>

				<div className="cardFlex">
					<div className="card70">
						<div className="card">
              <p className='cardTitle'>Información</p>
              <div className="cardData">
								{!autoEnvio ? 
									<div><label>Destinatario</label>
										<Link to={`/destinatario/${dnoAppId}`}>{dnoNombre+ ' ' +dnoApellido}</Link>
									</div>:
									<div><p><label>Destinatario</label>AutoEnvio</p></div>
								}
								<div><p><label>Tipo ID</label>{dnoTipoId}</p></div>
								<div><p><label>Número de ID</label>{dnoNoId}</p></div>
								<div><p><label>Celular</label>{dnoCelular}</p></div>
								<div><p><label>Banco</label>{nombreBanco}</p></div>
								<div><p><label>Titular</label>{titular}</p></div>
								{table === 'transferencias' && <div><p><label>BSB</label>{numeroBSB}</p></div>}
								{table === 'colombia' && <div><p><label>Tipo de Cuenta</label>{tipoCuenta}</p></div>}
								<div><p><label>No. de Cuenta</label>{numerodeCuenta}</p></div>
							</div>
            </div>

						{!comprobantePago ?
							<Comprobante
								dbTable={table}
								colcambios={colcambios}
								fechaMaxPago={fechaPago}
								fechaTransfer={fechaEmail}
								docRef={id}
								uidUsuario={transferencia.empresa ? transferencia.empresa : transferencia.usuario} 
								rteEmail={transferencia.remitente.email} 
								rteNombre={transferencia.remitente.nombre} 
								banco={nombreBanco}
								bsb={numeroBSB}
								titular={titular}
								cuenta={numerodeCuenta}
								total={total}
								emailAud={transferencia.transferencia.to} 
        				emailTasa={transferencia.transferencia.tasa} 
								razon={razonTrans}
								metodo={metodo}
								empresa={transferencia.empresa ? true : false}
							/>
							:
							<div className="card">
								<p className='cardTitle'>Documentos</p>
								<CardDoc documento={comprobantePago} label={'Comprobante'} trash={false} action={()=>{}} 
									text={colcambios.banco}/>
							</div>
						}

					{proTipo && 
						<div className="card">
							<p className='cardTitle'>Códigos</p>
							<div className="cardData">
								<div><h3>Promocional</h3></div>
								<div><p><label>Tipo</label>{proTipo}</p></div>
								<div><p><label>Código</label>{proCodigo}</p></div>
								<div><p><label>Descuento</label>{proDescuento}</p></div>
								{proAgenciaId && <div><p><label>Agencia</label>{proAgenciaId}</p></div>}
							</div>
						</div>
					}

					{observaciones &&
						<div className="card">
							<p className='cardTitle'>Observaciones</p>
							<div className="Observaciones">
								<p>{observaciones}</p>
							</div>
						</div>
					}
				</div>

				<div className="card30">
					<div className="card">
						<p className='cardTitle'>Resumen</p>
						<div className="cardResumen">
							<div><label>Valor Pagado</label><span>{CoinFrom(total)}</span></div>
							<div><label>Cargos Bancarios</label><span>{cargos !== 0 && "- "}{CoinFrom(cargos)}</span></div>
							{table !== 'colombia' && <div><label>Impuesto 4x1000</label><span>{FormatCop(cuatroMil)}</span></div>}
							{table === 'colombia' && <div><label>Servicio</label><span>{!servicio && "- "}{CoinFee(fee)}</span></div>}
							<div><label>Tasa de Cambio</label><span>{FormatCop(tasadeCambio)}</span></div>
							{table !== 'colombia' && <div><label>Servicio</label><span>{!servicio && "- "}{CoinFee(fee)}</span></div>}
							{table === 'colombia' && <div><label>Impuesto 4x1000</label><span>{FormatCop(cuatroMil)}</span></div>}
							<div><label>Valor a Recibir</label><span><b>{CoinTo(montoAud)}</b></span></div>
						</div>
					</div>
					
					<ExportInvoice
						transferencia={transferencia}
						table={table}
						factura={factura}
						id={id}
					/>
                        
					{estadoTrans === 'Procesada' && !calificada &&
						<CalificarServicio dbTable={table} rteAppId={rteAppId} id={id} rteNombre={rteNombre} rteApellido={rteApellido}/>}
				</div>
			</div>
			</> }
    </div>
  )
}

export default Trasnferencia;