import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import jsPDF from 'jspdf';
import { useConfig } from '../../../context/ConfigsContext';
import { useTrans } from '../../../context/TransferContext';
import { useAuth } from '../../../context/AuthContext';
import { useGetComisiones, useGetPorPagar } from '../../hooks/useGetInfo'
import ExportComisiones from './functions/Export';
import { LoadingCard } from '../../../components/loading/Loadings';
import { BtnActions, BtnGeneral } from '../../../components/buttons/Boton';
import SVG from 'react-inlinesvg'
import ImgPdf from '../../../img/icons/document-text-1.svg';
import ImgExcel from '../../../img/icons/document-download.svg';
import ImgCopy from '../../../img/icons/document-copy.svg';
import ImgNext from '../../../img/icons/arrow-right-1.svg';
import ImgPrev from '../../../img/icons/arrow-left-1.svg';
import ImgFilter from '../../../img/icons/filter-square.svg';
import ItemCard from '../../../components/items/ItemCard';
import { FiltroFechas } from '../../../components/tablas/FiltroTrans';
import { FiltroComision } from '../../../components/tablas/FiltroLabel';

const Comisiones = () => {

  const {truncate} = useTrans();
  const {infoUser, business} = useAuth();
  const [codeId, setCodeId] = useState();

  const [filtrar, setFiltrar] = useState(false);
  const [filtros, setFiltros] = useState([]);
  const [busqueda, setBusqueda] = useState({});
  const {comisiones, loading, Next, After, last, first} = useGetComisiones(filtros, busqueda, codeId);
  const {FormatAud, dateNumbers, Capitalize} = useConfig();
  const [totalMes, setTotalMes] = useState(0);
  const [porPagar] = useGetPorPagar(codeId);
  const [newData, setNewData] = useState('');

  useEffect(()=>{
    if(infoUser){
      if(infoUser.rol === 'empresa'){
        if(business){
          setCodeId(business.code)
        }
      }else{
        setCodeId(infoUser.codRef);
      }
    }
  },[infoUser, business])

	useEffect(()=>{
		if(comisiones){
			const sumall = comisiones.map(comision => comision.comision).reduce((prev, curr) => prev + curr, 0);
			setTotalMes(truncate(sumall,2));
			const temp = comisiones.map((comision)=>{
				return [
					comision.id.substring(0, 6).toUpperCase(),
					dateNumbers(comision.fecha).toUpperCase(),
					comision.nombre,
					comision.comision,
					comision.estado
				]
			});
			setNewData(temp);
		}
	},[comisiones, truncate, dateNumbers])

  const DownloadPDF = async() =>{
		const doc = new jsPDF({orientation: 'l', unit: 'pt', format: 'tabloid'})
		doc.autoTable({
			head: [['Referencia', 'Fecha', 'Nombre', 'Comisión']],
			body: newData
		})
		doc.save('Comisiones.pdf');
	}

	const DownloadExcel = (e) =>{
		e.preventDefault();
		ExportComisiones({newData, codeId, totalMes});
	}

  return (
    <div className='dashPage'>
    <Helmet><title>Comisiones</title></Helmet>
      <p className='pageTitle'>Comisiones</p>

        <div className='card'>
          <p className='cardTitle'>Link para Compartir</p>
          <button className='btnRefer' onClick={()=>navigator.clipboard.writeText(`https://colcambiosaustralia.com/?id=${codeId}`)}>
            <span>https://colcambiosaustralia.com/?id={codeId}</span> <SVG src={ImgCopy} />
          </button>
        </div>

        <div className="card">
          <p className="cardTitle">Comisiones</p>
          <div className='itemsCards'>
					  <ItemCard label={'Por pagar'} value={FormatAud(porPagar)} />
          </div>
          <div className='filtrosHeader'> 
				    <BtnActions Clases={'GreenColor'} Action={()=>{setFiltrar(!filtrar); if(filtrar){setBusqueda({})}}} Img={ImgFilter} Text={'Filtrar'} Disbaled={false} />
				    <FiltroComision filtros={filtros} setFiltros={setFiltros} />
			    </div>
			    {filtrar && <FiltroFechas busqueda={setBusqueda} />}  
          {loading ? <LoadingCard /> : comisiones.length > 0 ? <>
          <table className='tablaGeneral'>
            <thead>
              <tr>
                <th>Referencia</th>
                <th>Fecha</th>
                <th>Nombre</th>
                <th className='tdRight'>Comisión</th>
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>
              {comisiones.map((comision, i) => {
                return(
                  <tr key={i}>
                    <td>{comision.referencia.substring(0, 6).toUpperCase()}</td>
                    <td>{dateNumbers(comision.fecha).toUpperCase()}</td>
                    <td>{Capitalize(comision.nombre)}</td>
                    <td className='tdRight'>{FormatAud(comision.comision)}</td>
                    <td>{Capitalize(comision.estado)}</td>
                  </tr>
                  )
                })
              }
            </tbody>
            </table> 
            <div className="TableFooter">
              <div className="BtnBox">
                <BtnActions Clases={'GreenColor'} Action={DownloadExcel} Img={ImgExcel} Text={'Exportar a Excel'}  Disbaled={false} />
                <BtnActions Clases={'RedColor'} Action={DownloadPDF} Img={ImgPdf} Text={'Exportar a PDF'} Disbaled={false} /> 
              </div>
            </div>
            <div className="TableFooter">
              {first ? <BtnGeneral text={'Anterior'} img={ImgPrev} side={'L'} disabled={false}
                type="button" action={After} clases={'primaryC'} large={false} /> : <p></p>}
              {last ? <BtnGeneral text={'Siguiente'} img={ImgNext} side={'R'} disabled={false}
                type="button" action={Next} clases={'primaryC'} large={false} /> : <p></p>}
            </div>
          </> :   
            <div className="itemsEmpty">
              <p>Aún no tienes comisiones.</p>
            </div>
          }
        </div>
    </div>
  )
}

export default Comisiones