import React, { useEffect } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import { LoadingPage } from '../../../components/loading/Loadings';
import { updateUser } from '../../../data/ManageMe';

const CargarDatos = () =>{

  const {infoUser, setInfoUser} = useAuth();
  const navigate = useNavigate();
    
  //CONSULTAS
  useEffect(() => {
    if(infoUser){
      let externalUserId = infoUser.usuario
      if(infoUser.verificado === 'verificada'){
        axios.post('https://server-colcambios.herokuapp.com/api/getUser', {externalUserId})
          .then(res =>{
            const splitMultipleSpaces = (str) => {return str.trim().split(/\s+/);}
            const rels = splitMultipleSpaces(res.data.info.firstName)
            const rels2 = splitMultipleSpaces(res.data.info.lastName)
                
            let nombre
            let sdoNombre
            let apellido
            let sdoApellido

            if(rels.length === 1){
              nombre = rels[0].toString().toLowerCase()
              sdoNombre = ''
            }else{
              nombre = rels[0].toString().toLowerCase()
              sdoNombre = rels[1].toString().toLowerCase()
            }

            if(rels2.length === 1){
              apellido = rels2[0].toString().toLowerCase()
              sdoApellido = ''
            }else{
              apellido = rels2[0].toString().toLowerCase()
              sdoApellido = rels2[1].toString().toLowerCase()
            }

            // const nombre = rels[0].toString().toLowerCase()
            // const sdoNombre = rels[1].toString().toLowerCase()
            // const apellido = rels2[0].toString().toLowerCase()
            // const sdoApellido = rels2[1].toString().toLowerCase()
                    
            const fechaNac = res.data.info.dob
            const celular = res.data.phone

            const docs = res.data.info.idDocs.pop()
            const Doc = docs.idDocType

            let tipoDeDoc
            if(Doc === 'ID_CARD'){tipoDeDoc='Cédula de Ciudadanía'}
            if(Doc === 'DRIVERS'){tipoDeDoc='Permiso de Conducir'}
            if(Doc === 'PASSPORT'){tipoDeDoc='Pasaporte'}
                    
            const numeroDoc = docs.number
            const applicantId = res.data.id 
            const inspectionId = res.data.inspectionId

            setInfoUser({...infoUser, 
              fechaNacimiento: fechaNac,
              celular: celular,
              nombre: {
                nombre: nombre,
                segNombre: sdoNombre,
                apellido: apellido,
                segApellido: sdoApellido,
              },
              documento:{
                tipoDocumento: tipoDeDoc,
                numeroDocumento: numeroDoc,
              },
              applicantId:applicantId,
              inspectionId:inspectionId,
              actualizado: true
            })

            updateUser({
              usuario: externalUserId,
              dob: fechaNac,
              celular: celular,
              nombre: {
                nombre: nombre,
                segNombre: sdoNombre,
                apellido: apellido,
                segApellido: sdoApellido,
              },
              documento:{
                tipoDocumento: tipoDeDoc,
                numeroDocumento: numeroDoc,
              },
              applicantId:applicantId,
              inspectionId:inspectionId,
            }).then(()=>{
              navigate('/terminar-registro');
            })
          }).catch(err =>{
            console.log(err);
          })
        }}
  }, [infoUser, navigate, setInfoUser]);

  return(
    <>
      <Helmet><title>Cargando</title></Helmet>
      <LoadingPage />
    </>
  )
}

export default CargarDatos;