import React, { useState } from 'react';
import SVG from 'react-inlinesvg';
import ImgInfo from '../../img/icons/info-circle.svg';

const SetButton = (props) => {

  const{
    spn,
    alt,
    setSpn,
    value,
    setValue,
    title,
    option1,
    option2,
    desc1,
    desc2,
    extra
  } = props;

  const [info, setInfo] = useState(false);
  const handleToggle = () =>{setInfo(!info)};
  const handleBtn = (valor) =>{setSpn(false); setValue(valor)}
  
  return (
    <div className={!spn ? 'ProcessBtn' : 'ProcessBtn ProcessBtnError'}>
      <div className='BtnCardHead'><h3>{title}</h3><SVG src={ImgInfo} onClick={handleToggle}/></div>
      <div className={!info ? 'BtnCardBtnsA' : 'BtnCardBtns'}>
        <button className={!value ? 'BtnCardA':''} type='button' onClick={()=>{handleBtn(false)}}><span>{option1}</span></button>
        <button className={value ? 'BtnCardA':'' } type='button' onClick={()=>{handleBtn(true)}}><span>{option2}</span></button>
      </div>
      <div className={!info ? 'BtnCardInfo' : 'BtnCardInfoA'}>
        <p><b>{option1}:</b> {desc1}</p>
        <p><b>{option2}:</b> {desc2}</p>
        {extra && extra}
        <button type='button' onClick={()=>{setInfo(false)}}>Entendido!</button>
      </div>
      {spn && <span className='btnSpan'>{alt}</span>}
    </div>
  )
}

export default SetButton;