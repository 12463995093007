import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios'
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { getUnixTime } from 'date-fns';
import { useAuth } from '../../../context/AuthContext';
import { useTrans } from '../../../context/TransferContext';
import { useConfig } from '../../../context/ConfigsContext';
import { useGetBusiness } from '../../../hooks/useGetInfo';
import { BuscarPromo } from '../transferencia/components/BuscarCodigo';
import SetColcambios from '../../../components/forms/SetColcambios';
import SetButton from '../../../components/forms/SetButton';
import StudentData from './components/StudentData';
import { AddIncome } from '../../../data/ManageWallet';
import { HuboUnError, PopUpValid } from '../../../components/mensajes/MensajesClient';
import { ModalValidar } from '../../../components/modales/Modales';
import { InputCOP, InputAUD } from '../../../components/inputs/InputCoin';
import { BtnGeneral} from '../../../components/buttons/Boton';
import SVG from 'react-inlinesvg'
import animation from '../../../img/animation.svg';
import '../../../styles/newProcess.css';
import '../../../styles/processWallet.css';
import { CounterInput } from '../../../components/inputs/Counter';
import { NoWallet } from '../../error/components/Restringed';
import SelectorCalc from '../../../components/inputs/SelectorCalc';

const Recargar = () => {

  const {infoUser} = useAuth();
  const {OpenModal, setProcesando, FormatAud, FormatCop, sideFix, Capitalize, dateEmail} = useConfig();
  const Navigate = useNavigate();
  const [numero, setNumero] = useState(0)
  const {tasas} = useTrans();
  const [tasa, setTasa] = useState(0);
  const [tasaOld, setTasaOld] = useState(0);
  const [uid, setUid] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [walletID, setWalletID] = useState('');
  const [walletB, setWalletB] = useState('');
  const [walletN, setWalletN] = useState('');

  const [business] = useGetBusiness();
  const [logo, setLogo] = useState('');

  useEffect(()=>{
    if(business){
      setLogo(business.logo);
      setWalletN(business.name)
      setEmpresa(business.id)
      setWalletB(business.wallet)
    }
  },[business, Navigate])
  
  const [nameWallet, setNameWallet] = useState('');

  const [metodo, setMetodo] = useState(false);
  const [taxes, setTaxes] = useState(false);
  
  const fecha = new Date();
  const [cop, setCop] = useState(0);
  const [aud, setAud] = useState(100);
  const [total, setTotal] = useState(0);
  const [ammount, setAmount] = useState(0);
  const [impuesto, setImpuesto] = useState(0);
  const [metodoPago, setMetodoPago] = useState('Transferencia'); //true otro false transferencia
	const metodos = [{id: 1, text: 'Transferencia', text2: 'Sin Costo Adicional'}, {id:2, text: 'Otro Método de Pago', text2:'Costo Adicional'}]

  const [codigoPromo, setCodigoPromo] = useState();
	const [tipoCode, setTipoCode] = useState('');

  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [rol, setRol] = useState();
  const [tipoId, setTipoId] = useState('');
  const [numberId, setNumberId] = useState('');
  const [applicantId, setApplicantId] = useState('');
  const [inspectionId, setInspectionId] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState('AUD');
  const [pais, setPais] = useState('')
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
 
  const studentRef = useRef();
  const [externo, setExterno] = useState(false);
  const [colcambios, setColcambios] = useState();
  const childCol = useRef();

  const [spnAud, setSpnAud] = useState(false);
  const [spanAlertTo, setSpanAlertTo] = useState(false);
  const [spnMetodo, setSpnMetodo] = useState(false)
  const [spnTaxes, setSpnTaxes] = useState(false);
  const [altMetodo, setAltMetodo] = useState('');
  const [altTaxes, setAltTaxes] = useState('');

  const [open, setOpen] = useState(false);
  const Toggle = () =>{ const valor = open ? false : true; setOpen(valor)}

  useEffect(()=>{
    if(infoUser){
      setUid(infoUser.usuario)
      setName(infoUser.nombre.nombre)
      setLastName(infoUser.nombre.apellido)
      setRol(infoUser.rol)
      setTipoId(infoUser.documento.tipoDocumento)
      setNumberId(infoUser.documento.numeroDocumento)
      setApplicantId(infoUser.applicantId)
      setInspectionId(infoUser.inspectionId)
      setEmail(infoUser.email)
      setPhone(infoUser.celular)
      setCountry(infoUser.direccion.pais)
      setCity(infoUser.direccion.ciudad)
      setAddress(infoUser.direccion.direccion)
      if(infoUser.rol === 'empresa' || infoUser.rol === 'asesor' ){
        setEmpresa(true);
      }else{
        setEmpresa(false);
        setWalletID(infoUser.wallet)
        setNameWallet(infoUser.nombre.nombre+' '+infoUser.nombre.apellido);
      }
    }
  },[infoUser])

  //FUNCIONES
  const [valor, setValor] = useState(true);
  const cambiarInputCop = () =>{setValor(false);}
  const cambiarInputAud = () =>{setValor(true);}

  useEffect(()=>{
    if(metodo === false){setImpuesto(0);}
    if(metodo === true){setImpuesto(20000);}
  },[metodo])

  //FORMULAS

  useEffect(() => {
		if(tasas){
			if(codigoPromo){
				setTipoCode(codigoPromo.descuento)
				if(codigoPromo.descuento === 'Tasa'){
					if(country === 'COP'){
						setTasa(tasas.AUD.compra + codigoPromo.valor); setTasaOld(tasas.AUD.compra)
					}
					if(country === 'AUD'){
						setTasa(tasas.AUD.venta - codigoPromo.valor); setTasaOld(tasas.AUD.venta)
					}
					if(country === 'NZD'){
						setTasa(tasas.NZD.venta - codigoPromo.valor); setTasaOld(tasas.NZD.venta)
					}
				}
			}else{
				setTipoCode()
				if(country === 'COP'){
					setTasa(tasas.AUD.compra); setTasaOld();
				}
				if(country === 'AUD'){
					setTasa(tasas.AUD.venta); setTasaOld();
				}
				if(country === 'NZD'){
					setTasa(tasas.NZD.venta); setTasaOld();
				}
			}
		}
	}, [country, tasas, codigoPromo]);

  useEffect(()=>{
    if(!valor){
      setAud(Math.floor(cop / tasa));
    }else{
      setCop(Math.ceil(aud * tasa));
    }
  },[cop, aud, valor, tasa, impuesto])

  useEffect(()=>{
    if(numero > 0){
      if(!taxes){
        setTotal(Math.ceil((cop + impuesto) + ((numero * 4.99) * tasa)))
        setAmount(Math.floor((cop + (Math.ceil(numero * 4.99) * tasa)) / tasa))
      }else{
        setTotal(Math.ceil(cop + ((numero * 4.99) * tasa)))
        setAmount(Math.floor((cop + (Math.ceil(numero * 4.99) * tasa)) - impuesto / tasa))
      }
    }else{
      if(!taxes){
        setTotal(Math.ceil(cop + impuesto))
        setAmount(Math.floor(cop / tasa))
      }else{
        setTotal(Math.ceil(cop))
        setAmount(Math.floor((cop - impuesto) / tasa))
      }
    }
  },[aud, cop, impuesto, tasa, taxes, numero])

  //ACCIONES
  const Validar = (e) =>{
    e.preventDefault();
    let cuenta
    let student
    if (aud < 100){setSpnAud(true); return;}
    if (metodo === null){setSpnMetodo(true); setAltMetodo('Por favor Selecciona');return;}
    if (metodo === true){ if(taxes === null){setSpnTaxes(true); setAltTaxes('Por favor Selecciona');return;}}
    if (externo){
      const info = studentRef.current.validarInfo();
      if (!info){return}else{student = info}
    }
    const datas = childCol.current.validarCuenta();
    if(!datas){return}else{cuenta = datas; setColcambios(datas)}
    
    OpenModal(
      <ModalValidar 
        accion={()=>Submit(cuenta, student)} 
        titulo={'Registro de Ingreso'}
        mensaje={'Deseas registrar este ingreso?'}
        botonName={'Sí, Registrar'}
        botonClass={'btnGeneral primaryC'}
      />
    )
  }

  const Submit = (acco, est) =>{
    setProcesando(true);
    AddIncome({
      data:{
        date: getUnixTime(fecha),
        dateExp: ((fecha.getTime()) + 900000),
        status: 'Registrada',
        userID: uid,
        ...empresa && {empresa: empresa},
        nameWallet: empresa ? walletN : nameWallet,
        wallet: empresa ? walletB : walletID,
        type: 'ingreso',
        colcambios: acco,
        ...codigoPromo && {promociones:{
					codigo: codigoPromo.id,
					tipo: codigoPromo.tipo,
					descuento: codigoPromo.descuento,
					...codigoPromo.agenciaId && {agenciaId: codigoPromo.agenciaId},
				}},
        values:{
          ammount: ammount,
          tasa: tasa,
          total: total,
          taxes: impuesto,
        },
        userInfo:{
          name: name,
          lastName: lastName,
          document: {
            tipoId: tipoId,
            numberId: numberId,
            applicantId: applicantId,
            inspectionId: inspectionId
          },
          email: email,
          phone: phone,
          pais: country,
          ciudad: city,
          direccion: address
        },
        ...externo && {cliente: est},
        ...logo && {logo: logo}
      }
    }).then(async(docRef)=>{
      const datos = {
        email: email,
        type: 'ingreso',
        estado: 'Registrada',
        id: docRef.id.substring(0, 6).toUpperCase(),
        nombre: empresa ? Capitalize(walletN) : Capitalize(nameWallet),
        fecha: dateEmail(getUnixTime(fecha)).toUpperCase(),
        tasa: FormatCop(tasa),
        aud: FormatAud(ammount),
        cop: FormatCop(total),
        taxes: FormatCop(impuesto),
        msj: 'fue registrada con éxito. A continuación encontrarás la información e instrucciones de pago'
      }

      await axios.post('https://server-colcambios.herokuapp.com/api/billetera',{datos})

      setProcesando(false)
      OpenModal(<PopUpValid mensaje={'Se ha registrado el ingreso Correctamente.'} />);
      Navigate(`/mi-billetera/pagar/${docRef.id}`)
      
    }).catch(()=>{
      setProcesando(false)
      OpenModal(<HuboUnError />);
    })
  }

  return <>
    <Helmet><title>Recargar</title></Helmet>
    <div className='dashPage'>
      <p className="pageTitle">Nueva Recarga</p>
      {!walletB ? <NoWallet /> :
        <div className={open ? 'windowProcess bottomPadding' : 'windowProcess'}>
          <form className={sideFix ? 'windowFormRowX' : 'windowForm windowLarge' }>
            <div className='windowImagen'>
              <SVG src={animation}/>
            </div>
            <div className='windowContent'>
            <div className='newCalc'>
              <InputAUD label={'¿Cuanto deseas recargar?'} spn={spnAud} setSpn={setSpnAud} value={aud}
              setValue={setAud} fill={'off'} onClick={cambiarInputAud} />
              <div className='InfoPoints'>
                {aud < 10 && <span>Monto Mínimo: <b>$100 AUD</b></span>}
              </div>
              <div className='optsCalc'>
                <div className='optCalc'>
                  <div className='optItem'><div className='simbolb'>-</div><span>{FormatCop(taxes)}</span></div>
                  <SelectorCalc label={'Método de Pago'} value={metodoPago} setValue={setMetodoPago} options={metodos} />
                </div>
                <div className='optCalc'>
                  <div className='optItem'><div className='simbolb'>{country === 'COP' ? '×':'÷'}</div><span>{tasaOld && <label className={tipoCode === 'Tasa' ? 'labeled' : ''}>{FormatCop(tasaOld)}</label> }
                  <label> {FormatCop(tasa)}</label></span></div>
                  <p>Tasa de Cambio</p>
                </div>
              </div>
            </div>
            <InputCOP label={'Recarga en Pesos'} spn={spanAlertTo} setSpn={setSpanAlertTo} value={cop}
            setValue={setCop} fill={'off'} onClick={cambiarInputCop} />

            <BuscarPromo usuario={uid} setCodigo={setCodigoPromo} check={codigoPromo}/>	

            {externo ? <StudentData ref={studentRef} setShow={setExterno} /> : 
              rol !== 'Usuario' &&
              <BtnGeneral text={'¿Es una factura para un Cliente?'} img={''} side={'L'} disabled={false} type="button" 
                action={()=>{setExterno(true);}} clases={'primaryC'} large={true} />
            }

            <div className='cardCounter'>
              <p className='textP'>¿Cuántas transferencias realizarás con este ingreso de tu cliente?</p>
              <div className='counterMin'>
                <CounterInput count={numero} setCount={setNumero} />
              </div>
            </div>
            
            <SetButton title={'1. Método de Pago'} value={metodo} setValue={setMetodo} 
              setSpn={setSpnMetodo} spn={spnMetodo} alt={altMetodo}
              option1={'Transferencia Electrónica'} option2={'Otro Método de Pago'}
              desc1={'Las transferencias electrónicas no generan costos adicionales.'}
              desc2={'Los métodos de pago diferentes a las transferencias electrónicas generan un costo adicional.'}
              extra={'En caso de realizar el pago diferente al seleccionado la transferencia estará sujeta a recotización.'}
            />

            {metodo &&
              <SetButton title={'2. Costos Bancarios'} value={taxes} setValue={setTaxes} 
                setSpn={setSpnTaxes} spn={spnTaxes} alt={altTaxes}
                option1={'Adicionar Costos'}option2={'Incluir Costos'}
                desc1={'Se adicionan los costos bancarios al valor que envías.'}
                desc2={'Se descuentan los costos bancarios del valor a enviar.'} extra={''}
              />
            }
                
            <SetColcambios ref={childCol} colcambios={colcambios} />

            <BtnGeneral text={`IR A PAGAR ${FormatCop(total)}`} img={''} side={'L'} disabled={false} type="button" 
              action={Validar} clases={'primaryC'} large={true} />
                
            </div>
          </form>
          {/* <div className={sideFix ? 'windowResume resumePlain' : 'windowResume'}>
            <button className='btnResume' onClick={()=>{Toggle()}}>{open ? 'Ocultar Resumen' : 'Ver Resumen'}</button>
            <p className='cardShortTitle'>Resumen</p>
            <div className='shortInfo'>
              <p><span>Pagas</span>{FormatCop(total)}</p>
              <p><span>Recibes</span>{FormatAud(ammount)}</p>
            </div>
            <div className={open ? 'windowTicket' : 'windowTicket windowTicketNone'}>
              <div><span>Total a Pagar</span><span>{FormatCop(total)}</span></div>
              <div><span>Cargos Bancarios</span><span>{FormatCop(impuesto)}</span></div>
              <div><span>Tasa de Cambio</span>
              {tipoCode === 'Tasa' ?
                <div className='DatosPromo'>
                  <span><label className='labeled'>{FormatCop(tasadeCambio)}</label><br/>
                  {FormatCop(tasa)}</span>
                </div>
                :<span>{FormatCop(tasa)}</span>
              }
              </div>
              {numero > 0 && <div><span>Número de Transferencias</span><span>{numero + ' x 4.99 AUD'}</span></div>}
              <div className='lineUp'><span>Valor a Recargar</span><span>{FormatAud(ammount)}</span></div>
            </div>
          </div> */}
        </div>
      }
    </div>
  </>
}

export default Recargar;